
.Results {
    height: 100vh;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;


    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    
}

.result-container {
    // display: flex;
    // flex-flow: column;
    // justify-content: center;
    // align-items: center;
   
    // height: 100%;
    // width: 100%;

    padding-left: 100px;
    padding-right: 100px;
   
    @media screen and (max-width: 992px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
   

}

.search-banner {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    

    @media screen and (max-width: 992px) {
        display: flex;
        flex-flow: column;
    }
    
    .search-banner-img {
        width: 65%;
        @media screen and (max-width: 992px) {
            width: 100%;
            margin-bottom: 1em;
        }

        @media screen and (max-width: 767px) {
           display: none;
        }
    }


    .search-banner-results-txt-wrap {
        display: flex;
        flex-flow: column;
       text-align: left;
       margin-left: 2em;
      
    @media screen and (max-width: 1024px) {
        margin-left: 2em;
    }
    @media screen and (max-width: 992px) {
        margin-left: 0em;
    }
    
     

       .result-title {
        font-family: 'DM Sans';
        font-size: 32px;
        font-weight: 700;
        color: #3E2222;
        text-align: left;

       

            @media screen and (max-width: 767px) {
                font-size: 24px;
            }
       }

       .result-subtitle {
        font-family: 'DM Sans';
        font-size: 19px;
        font-weight: 400;
        color: #A38C8C;
        text-align: left;
        margin-bottom: 1.5em;
       }

       .result-paragraph {
        font-family: 'DM Sans';
        font-size: 16px;
        font-weight: 400;
        color: #3E2222;
       }

    }


}





.search-results-label {
    font-family: 'DM Sans';
    font-size: 18px;
    color: #3E2222;
    font-weight: 500;
    margin-left: 2em;
}


// LAW FIRMS

.law-firms-boxes-wrap {
    display: flex;
    flex-flow: row;

    @media screen and (max-width: 767px) {
        flex-flow: column;
    }
}


.firm-page-wrapper {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 50px;
    padding-left: 100px;
    padding-right: 100px;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-flow: wrap-reverse;
    }

    @media screen and (max-width: 992px) {
        display: flex;
        flex-flow: wrap-reverse;
    }

    @media screen and (max-width: 767px) {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
       
    }
    


}



.law-firm-search-img {
   width: 250px;
   margin-bottom: 2em;
   margin-top: 1em;
}