.no-result-title {
    margin-top: 5em;
}


.Location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 90vh;
}

body {

    background-color: #f6f0f0;
   
    
}

.location-search-subtitle-msg {
    margin-top: 2em;
    font-family: 'DM Sans';
    color: #a38c8c;
    background: rgba(163, 140, 140, 0.148383);
    border-radius: 11px;
    padding: 10px;
}

.location-page-num {
    margin-top: 7rem;
    font-family: 'DM Sans';
    color: #d80027;
    background: rgba(163, 140, 140, 0.148383);
    border-radius: 11px;
    padding: 10px;
    font-weight: bold;
}

.search-header {
    width: 100%;
    height: 5rem;
    background-color: white;

    .lawyer-search-logo {
        margin: 15px 30px;
        height: 3rem;
        width: 11.5rem;

        @media screen and (max-width: 992px) {
            margin: 15px 15px;
        }
        @media screen and (max-device-width:767px){
            margin: 15px 15px;
        }

    }
  
}





.Paralegal-Search,
.Lawyer-Search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-flow: column;
    background-color: #f6f0f0;

    @media screen and (max-width: 992px) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    @media screen and (max-device-width: 767px) {
        height: 100%;
    }
}

.gender-buttons-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}


.search-back-btn {
    margin-left: 30px; 
    margin-top: 20px;
    

    @media screen and (max-device-width: 767px) {
        margin-left: 15px;
    }
}

.search-back-arrow-circle {
    stroke-width: 1.5;

    &:hover {
        cursor: pointer;
        stroke: #D80027;
        transition: .15s;
    }
}

.location-search-title {
     font-family: 'DM Sans';
    font-size: 32px;
    font-weight: 400;
    color: #3E2222;
    text-align: center;


    @media screen and (max-width: 992px) {
   
    }

    @media screen and (max-device-width: 767px) {
        font-size: 26px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-device-width: 568px) {
        font-size: 26px;
        padding-left: 10px;
        padding-right: 10px;
    }

       

}

.location-search-subtitle {
     font-family: 'DM Sans';
    font-size: 19px;
    font-weight: 200;
    color: #A38C8C;
    text-align: center;
    margin-bottom: 1em;

    @media screen and (max-device-width: 767px) {
        // font-size: 26px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-device-width: 568px) {
        // font-size: 26px;
        padding-left: 30px;
        padding-right: 30px;
    }

}

.location-search-button {
    margin-top: 2.5em;
     font-family: 'DM Sans';
    font-size: 24px;
    color: #ffffff;
    text-align: left;
    background-image: linear-gradient(270deg, #e42254 3%, #db3434 93%);
    border-radius: 8px;
    border: none;
    padding: 12px 25px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: translateY(1px);
    }
}




// svg:hover {
//     fill: blue;
// }

.fa-play {
    margin-bottom: 1px;
    margin-left: 0.5em;
    font-size: 13px;
}

.location-select-input {
    outline: none;
    font-size: 16px;
     font-family: 'DM Sans';
    font-weight: 300;
    background: #ffffff;
    color: #111111;
    border-radius: 8px;
    border: 1px solid #d3b9b9;
    padding: 10px 23px;
    width: 170px;
    height: 55px;
    margin-top: 20px;
    margin-bottom: -20px;
}

.location-select-input:focus {
    border: 1px solid #3689ff;
}

//
.status-search-lawyer-button {
    margin-top: 20px;
    margin-right: 25px;
    height: 70px;
    width: 230px;
    border-radius: 8px;
    border: 1px solid #A38C8C;
    background: #ffffff;
     font-family: 'DM Sans';
    font-weight: 400;
    font-size: 20px;
    color: #A38C8C;
    text-align: center;

    &:hover {
        // background: #FFF5F5;
       
        box-shadow: 0 0 20px -10px rgba(255, 187, 187, 0.5);
        cursor: pointer;
      
        background-color: #F6EAEA;
        transition: .15s;
        color: #D80027;
        border-color: #D80027;
    }


    &:focus {
        outline: none;
        background: #fff5f5;
        border: 1px solid #ff0000;
    }

    // &:active {
    //     background: #fff5f5;
    //     border: 1px solid #ff0000;
    //     box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
    //     cursor: pointer;
    //     opacity: 0.9;
    //     transform: translateY(1px);
    // }

    @media screen and (max-device-width: 767px) {
        margin-right: 0px;
    }
}

// selected button
.btn-selected {
    background: #fff5f5;
    border: 1px solid #ff0000;
    box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
    cursor: pointer;
    opacity: 0.9;
}

.search-button-question {
    font-size: 14px;
    margin-top: 10px;
    color: #8a8a8a;
    font-weight: 200;
}

.law-field-options {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding-left: 100px;
    padding-right: 100px;

    @media screen and (max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.result-search-subtitle-1 {
    margin-top: 20px;
    // text-align: center;
    color: #716a6a;
}

.result-search-subtitle-2 {
    // text-align: center;
    color: #716a6a;
}

// Status Search
.location-search-button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.result-empty-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.result-empty-form {
    margin-top: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.get-update-txt {
    font-family: 'DM Sans';
    font-size: 24px;
    font-weight: 300;
    color: #A38C8C;
}


// icon
.location-search-header-icon {
    width: 50px;
    margin-bottom: 30px;
}

.type-search-header-icon {
    width: 70px;
    margin-bottom: 30px;
}



.gender-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 170px;
    width: 230px;

    .gender-svg {
        margin-bottom: 15px;
    }

    &:hover {
        .gender-svg > path {
            fill: #D80027;
        }
     
    }

    @media screen and (max-width: 992px) {
        // margin: 15px 15px;
    }
    @media screen and (max-device-width:767px){
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row;
        padding-left: 10px;
        padding-right: 20px;
        height: 80px;
        width: 230px;

        .gender-svg {
            margin-bottom: 0px;
        }
    }


}

.law-type-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    
    // padding-left: 10px;
    // padding-right: 20px;
    height: 100%;

    height: 170px;
    width: 230px;

    .law-type-svg {
        margin-bottom: 15px;
    }

    &:hover {
        .law-type-svg > path {
            fill: #D80027;
        }
     
    }

    @media screen and (max-device-width:767px){
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row;
        padding-left: 10px;
        padding-right: 20px;
        height: 80px;
        width: 280px;

        .law-type-svg {
            margin-bottom: 0px;
        }
    }
}



