.firm-employee-profile{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding:20px;
    flex-direction:row;
    /* position: relative; */
  
}
/* .firm-employee-profile >h5{
    display: block;
} */


.social-profile-website-link:hover {
    cursor: pointer;
}