.AddAvailability {
    display: flex;
    flex-flow: row;
    background-color: #fff;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}



/* VIEW AVAILABILITY */
.availability-view-content-container {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    margin-left: 570px;
    // margin: 25px;
}


.availaibility-tag-heading-container {

    // Tag Heading (i.e. Handle, Status, Field)
    strong {
      font-family: "Roboto";
      font-weight: 400;
      color: #8B7474;
    }

    // Tag Value (i.e. hadiaamir021, Paralegal, Landlord or Tenant)
    h5 {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 1.1em;
    }
}





.availability-view-content-button-container {

    margin-left: 30px;
  
    /* EDIT PROFILE BUTTON */
    .availability-view-content-button {
      margin-right: 2.5em;
      margin-bottom: 20px;
      font-size: 16px;
      background: #a21022;
      border: 0px;
      width: 140px;
      height: 55px;
      border-radius: 8px;
      font-family: "Sarabun";
      font-weight: 700;
      color: #fff;
      background: #D0021B;
      box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);
  
      &:hover {
        background: #D6394A;
        box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
        transition: .3s;
        cursor: pointer;
      }
  
      &:focus {
        outline: none;
      }
    }
  
  }





/* EDIT AVAILABILITY */
.edit-avail-content-container {
    display: flex;
    flex-flow: column;
    margin: 25px;
    margin-right: 0;
    margin-left: 560px;
}

.edit-avail-form-container {
    display: flex;
    flex-flow: row wrap;
    background: #FFFFFF;
    border-radius: 15px;
    height: auto;
    padding: 30px 50px;
}

.edit-avail-input-container-1, .edit-avail-input-container-2, .edit-avail-input-container-3, .edit-avail-input-container-4, .edit-avail-input-container-5, .edit-avail-input-container-6, .edit-avail-input-container-7 {
    display: flex;
    flex-flow: column;
}

/* margin right on selected fields */
.edit-avail-input-container-1, .edit-avail-input-container-3, .edit-avail-input-container-5, .edit-avail-input-container-7 {
    margin-right: 20px;
}

/* margin top on selected fields */
.edit-avail-input-container-3, .edit-avail-input-container-4 ,.edit-avail-input-container-5, .edit-avail-input-container-6, .edit-avail-input-container-7 {
    margin-top: 20px;
}


.edit-avail-day-text {
    font-family: "Sarabun";
    font-size: 18px;
    font-weight: 700;
}

.edit-avail-select-container {
    display: flex;
    flex-flow: row;
    margin-left: 20px;
}

.edit-avail-select-input-1 {
    margin-right: 20px;
}

/* EDIT AVAILABILITY BUTTON */
.edit-avail-update-button-container {
    margin-top: 8em;
    margin-right: 60px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.edit-avail-update-button {
    margin-top: 20px;
    font-size: 16px;
    background: #a21022;
    border: 0px;
    width: 240px;
	height: 55px;
    border-radius: 8px;
    font-family: "Sarabun";
    font-weight: 700;
    color: #fff;
	background: #D0021B;
	box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);
}

.edit-avail-update-button:hover {
	background: #D6394A;
    box-shadow: 0 0 10px 0 rgba(211,185,185,0.99);
    transition: .3s;
    cursor: pointer;
  }

  .edit-avail-update-button:focus {
    outline: none;
  }



  .edit-avail-return-button {
    margin-top: 20px;
    font-size: 16px;
    border: 0px;
    width: 240px;
	height: 55px;
    border-radius: 8px;
    font-family: "Sarabun";
    font-weight: 700;
    color: #D0021B;
	background: rgb(255, 255, 255);
	box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);
}

.edit-avail-return-button:hover {
	background: rgb(248, 248, 248);
    box-shadow: 0 0 10px 0 rgba(211,185,185,0.99);
    transition: .3s;
    cursor: pointer;
  }

  .edit-avail-return-button:focus {
    outline: none;
  }




