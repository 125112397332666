@import '../../../../style/_variables.scss';


// 
.no-profile-dashboard-container {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 90vh;
	width: 100%;
}

.no-profile-dashboard-img {
	width: 400px;
}

.no-profile-dashboard-title {
	font-family: 'Roboto';
	font-size: 24px;
	margin-top: 30px;
}

.no-profile-dashboard-subtitle {
	font-family: 'Roboto';
	font-size: 18px;
	color: rgb(129, 98, 98);
}

.no-profile-dashboard-btn {
	margin-top: .5em;
	font-size: 22px;
	border: 0px;
	width: auto;
	// height: 30px;
	padding: 15px;

	font-family: "Lato";
	font-weight: 700;
	color: #007AFF;
	background: #CBE4FF;
	border-radius: 8px;

	&:hover {
		opacity: .8;
		cursor: pointer;
		text-decoration: none;
	  }
  
	  &:focus {
		outline: none;
	  }
}

// 

.Dashboard {
	background-color: #F6F0F0;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-left: 220px;
	padding: 30px 30px 40px;
	width: 100%;
	height: 100%;
}

.Dashboard > h1 {
	padding: 20px 0px;
	margin-left: 10px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 36px;
	color: #3F2222;
}	

.dashboard-row-1 {
	display: flex;
	flex-flow: row;

	margin-left: 10px;
	justify-content: space-between;
	margin-bottom: 1.2em;
	height: 130px;

	@media screen and (max-width: 992px) {
		display: flex;
		flex-flow: row wrap;

		.dashboard-row-1-card-1 {
			margin-bottom: 30px;
		}
	}

	
	div:first-child, div:nth-child(2), div:nth-child(3) {
		// margin-right: 1.2em;
	}

	.dashboard-row-1-card-1, .dashboard-row-1-card-2 {
		display: flex;
		flex-flow: row;
		background-color: #FFFFFF;
		padding: 30px;
		height: 130px;
		width: 100%;
		border-radius: 18px;

	}	


	.dashboard-row-1-card-2 {
		justify-content: flex-start;
	}


	.dashboard-row-1-card-1-location {
		margin-left: 10px;
	}

	.dashboard-row-1-card-2 {
		// margin-right: 30px;
		background-color: #BD2C2C;
	}

	.dashboard-row-1-card-2-image {
		height: 110px;
	}




	.dashboard-row-1-card-1-image {
		min-height: 80px;
		min-height: 80px;
		border-radius: 10px;
	}

	.dashboard-row-1-card-1-title-container {
		display: flex;
		flex-flow: column;
		margin-left: 30px;
		justify-content: center;
		// justify-content: space-between;

		.dashboard-row-1-card-1-title {
			font-size: 20px;
			color: #3E2222;
		}

		.dashboard-row-1-card-1-location {
			font-size: 18px;
			color: #A38C8C;
		}
	}


	.dashboard-row-1-card-2-title-container {
		display: flex;
		flex-flow: column;
		justify-content: center;
		font-family: 'Roboto';
		margin-left: 20px;

		.dashboard-row-1-card-2-title {
			font-size: 20px;
			color: #FFFFFF;
			font-weight: 400;
		}

		.dashboard-row-1-card-2-message {
			font-size: 18px;
			color: #FFFFFF;
			font-weight: 100;
		}
	}



}

.dashboard-row-2 {
	display: flex;
	flex-flow: row;

	margin-left: 10px;
	justify-content: space-between;
	margin-top: 10px;
	
	@media screen and (max-width: 992px) {
		display: flex;
		flex-flow: row wrap;
		margin-top: 200px;
		
		.dashboard-row-2-card-1 {
			margin-bottom: 30px;
			
		}
	}

	.dashboard-row-2-card-1 {
		padding: 30px;
		padding-top: 25px;
		font-weight: 700;
		background-color: #FFFFFF;
		border-radius: 18px;
		font-family: 'Roboto';
		font-size: 18px;
		width: 35%;
		height: 100%;
	}

	.dashboard-row-2-card-1-container {
		display: flex;
		justify-content: space-between;
		flex-flow: column;
		
		color: #3E2222;
		margin-top: 15px;
		border: 1px solid #E5E5EA;
		border-radius: 18px;
		height: 100%;
		width: 290px;
		padding: 20px 20px;
		max-height: 260px;
		// font-size: 18px;
		width: 100%;
		overflow-y: scroll;
	
		.dashboard-row-2-card-1-daytime-container {
			display: flex;
			justify-content: space-between;
		}

		.dashboard-row-2-card-1-day { 

		}
		
		.dashboard-row-2-card-1-time { 
			font-weight: 300;
			font-size: 20px;
			font-family: 'Lato';
		}
	}
}

.dashboard-row-2-card-1-header-container {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
}
.dashboard-row-2-card-1-header-container span {
	color: #3E2222;
}


.dashboard-row-3-card-1-btn-text {
	
	color: #D0021B;
	&:hover {
		color: #EC3F4F;
	}
}



.dashboard-row-2-card-1-btn {
	margin-top: .5em;
	font-size: 16px;
	border: 0px;
	width: 70px;
	height: 30px;

	font-family: "Lato";
	font-weight: 700;
	color: #007AFF;
	background: #CBE4FF;
	border-radius: 20px;

	&:hover {
		opacity: .8;
		cursor: pointer;
	  }
  
	  &:focus {
		outline: none;
	  }
}



.dashboard-row-2-card-2 {
	margin-left: 30px;
	padding: 30px;
	padding-top: 25px;
	font-weight: 700;
	background-color: #FFFFFF;
	border-radius: 18px;
	font-family: 'Roboto';
	font-size: 18px;
	width: 65%;
	
}


.dashboard-row-2-card-2-container {
	margin-top: 15px;
	max-height: 260px; 
	overflow-y: scroll;
	z-index: 1;
	border: 1px solid #E5E5EA;
	border-radius: 18px;
	// padding-left: 20px;
	// padding-right: 20px;
	// padding-bottom: 20px;
}

.dashboard-row-2-card-2-image {
	border-radius: 50%;
	width: 70px;
}

.dashboard-row-2-card-2-row { 
	
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	// padding-top: 20px;
	// padding-bottom: 30px;
}

.dashboard-row-2-card-2-row-label {
	font-family: "Roboto";
	font-weight: 400;
	background-color: #D7E5C2;
	border-radius: 20px;
	width: auto;
	color: #4A6F42;
	padding-left: 5px;
	padding-right: 5px;
}

.dashboard-row-2-card-2-name-wrapper, .dashboard-row-2-card-2-service-wrapper {
	@include flex-column;

}

.dashboard-row-2-card-2-row-value {
	font-weight: 100;
}








.dashboard-row-3 {
	margin-left: 10px;
	margin-top: 30px;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	
	
	font-weight: 700;

	border-radius: 18px;
	font-family: 'Roboto';
	font-size: 18px;

}

// .dashboard-row-3-card-1, .dashboard-row-3-card-2, .dashboard-row-3-card-3
// {
// 	display: flex;
// 	justify-content: space-between;
// 	flex-flow: column;
	
// 	// justify-content: center;

// 	padding: 30px; 
// 	padding-top: 25px;
// 	background-color: #FFFFFF;
// 	border-radius: 18px;
// 	max-height: 150px;
// 	width: 100%;


// }



// .dashboard-row-3-card-2-container, .dashboard-row-3-card-3-container {

// 	img {
// 		margin-top: -10px;
// 		// width: 80%;
// 	}
	
// }

// .dashboard-row-3-card-1, .dashboard-row-3-card-2 {
// 	margin-right: 25px;
// }



// .dashboard-row-3-card-1-container {
// 	@include flex-column;
// }






// PROFILE CARD
.dashboard-profile-card {
	display: flex;
	justify-content: center;
	flex-flow: row;
	align-items: center;
	background-color: #FFFFFF;
	padding: 30px 25px 30px 25px;
	border-radius: 10px;
	box-shadow: 0 0 20px -10px rgba(0,0,0,0.50);
	flex-grow: 1;

	.profile-view-image {
		width: 140px;
		height: 140px;
		border-radius: 50%;
	  }
}	



.dashboard-profile-card-text-container {
	margin-left: 25px;
}

.dashboard-profile-card-status {
	font-family: "Sarabun";
	font-weight: 700;
	font-size: 24px;
	color: #252525;
	text-align: left;
}

.dashboard-profile-card-field {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 18px;
	color: #8C7475;
	text-align: left;
	
}

.dashboard-profile-card-field-container {
	max-width: 340px;
}

.dashboard-profile-card-address {
	margin-top: 5px;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 18px;
	color: #949494;
	text-align: left;
}


// 


//   Profile/Reviews Count Card
.dashboard-view-count-card {
	padding: 20px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	background-color: #FFFFFF;
	box-shadow: 0 0 20px -10px rgba(0,0,0,0.50);
	border-radius: 8px;
	height: auto;
	// max-height: 200px;
	flex-grow: 2;
	// width: 100%;
	text-align: center;
	// margin-bottom: 2.5em;
	// margin-right: 3em;


	span:first-child {
		font-family: "Roboto";
		font-weight: 700;
		font-size: 48px;
		color: #252525;
	}

	span:nth-child(2) {
		font-family: "Roboto";
		color: #949494;
	}
}

//	Client Connection Count Card
.dashboard-connections-count-card {
	padding: 20px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	background-image: linear-gradient(222deg, #F10707 0%, #D61B4D 100%);
	box-shadow: 0 0 20px -10px rgba(0,0,0,0.50);
	border-radius: 8px;
	flex-grow: 2;

	text-align: center;
	span:first-child {
		font-family: "Roboto";
		font-weight: 700;
		font-size: 48px;
		color: #FFFFFF;
	}

	span:nth-child(2) {
		font-family: "Roboto";
		color: #FFFFFF;
	}
}


// Share card
.dashboard-share-card-container {
	display: flex;
	flex-flow: column;
	flex-grow: 2;
	

	div:first-child {
		margin-bottom: 1.2em;
	}
}


//	Social Profile
.dashboard-social-profile-card {
	padding: 20px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	background-color: #FFFFFF;
	box-shadow: 0 0 20px -10px rgba(0,0,0,0.50);
	border-radius: 8px;
	height: 100%;
	flex-grow: 1;
	text-align: center;

	img {
		margin-bottom: 1em;
		width: 130px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	span {
		font-family: "Roboto";
		color: #949494;
		padding: 15px;
	}
}



	/* VIEW BUTTON */
	.dashboard-social-profile-button {
		display: block;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 20px;
		margin-top: 20px;
		font-size: 16px;
		background: #a21022;
		border: 0px;
		width: 140px;
		height: 55px;
		border-radius: 8px;
		font-family: "Sarabun";
		font-weight: 700;
		color: #fff;
		background: #D0021B;
		box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);
  
	  &:hover {
		background: #D6394A;
		box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
		transition: .3s;
		cursor: pointer;
	  }
  
	  &:focus {
		outline: none;
	  }
	}
  


.dashboard-share-card {
	padding: 20px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	background-color: #FFFFFF;
	box-shadow: 0 0 20px -10px rgba(0,0,0,0.50);
	border-radius: 8px;
	min-height: 120px;
	height: 100%;
	text-align: center;


	span {
		font-family: "Roboto";
		color: #949494;
		padding: 15px;
	}

	

}






table {
	border-collapse: column;
}

/*header text*/
th {
	font-size: 18px;
	font-weight: 400;
	color: #4A4A4A;
}

/*inside text*/
td {
	font-size: 16px;
	font-weight: 300;
	/*color: #666666;*/
}

th, td
 {
  text-align: left;
}

tr:nth-child(odd) {
	background: rgba(149,57,57,0.10);
}



/*  */

.dashboard-table {
  	border-radius: 10px;
  	// overflow: hidden;
	perspective: 1px;
	border-collapse: collapse;
    table-layout: fixed;
 	width: 100%;
  	white-space: nowrap;

	margin-top: 32px;
	background: #FFFFFF;
	box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
}

.dashboard-table thead th {
	border-bottom: none;
}

.dashboard-table td {
	border: none;
	font-family: 'Sarabun';
	font-weight: 200;
	text-align: left;
	white-space: nowrap;
  	// overflow: hidden;
  	text-overflow: ellipsis;

	padding: 15px 20px 10px 20px;
  	width: 100%;
}



/*workflow table - header*/
.dashboard-table th {
	width: 100%;
	font-family: 'Sarabun';
	font-weight: 900;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	text-align: left;
	background-color: #fff;
	border: 0;
}

.dashboard-table thead tr {
	display: flex;
	flex-flow: row;
	width: 635px;
}





.dashboard-table th:last-child, .table td:last-child {
	text-align: right;
	padding-right: 20px;
}


.dashboard-table tbody {
//   height: 500px;
//   display: block;
//   overflow-y: auto;
//   overflow-x: hidden;
}

.dashboard-table thead {
	display: block;
	overflow-x: hidden;
}

.dashboard-table tbody tr {
	display: flex;
}


/*  */

/*  */
/* #auth-padding {
	padding-left: 100px;
	padding-top: 2em;
	padding-bottom: 2em;
} */

#auth-title {
	font-family: 'Open Sans';
	font-weight: 700;
	font-size: 42px;
	color: #443C3C;
}

#auth-sub-title {
	font-family: 'Lato';
	font-weight: 300;
	font-size: 21px;
	color: #8B7474;
}

#auth-header-title {
	font-family: 'Lato';
		font-weight: 200;
		font-size: 20px;
		color: #A29191;
}

.display-4 {
	font-family: 'Open Sans';
	font-weight: 900;
	color: #252525;
}


.mb-4 {
	font-family: 'Open Sans';
	font-weight: 700;
	color: #252525;
}


.dash-info-card1 {
	border-radius: 20px;
	padding: 1.5em;
	color: #252525;
	margin-bottom: 3em;
	box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
}

.dash-info-card2 {
	border-radius: 20px;
	padding: 1.5em;
	color: #252525;
	box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
}


.profile-buttons {
	display: flex;
	align-items: baseline;
	margin-bottom: 2.5em;
	margin-top: 2em;
}

.profile-actions {
	display: flex;
	text-decoration: none;
	justify-content: center;
	margin-right: 2em;
	padding: 1em;
	width: 100%;
	border-radius: 20px;
	box-shadow: 0 6px 10px 0 rgba(0,0,0,0.16), 0 1px 12px 0 rgba(0,0,0,0.18), 0 3px 5px 0 rgba(0,0,0,0.20);
}

.profile-action-color1 {
	background-image: linear-gradient(-46deg, #F5515F 0%, #9F041B 100%);
}
.profile-action-color2 {
	background-image: linear-gradient(-45deg, #FAD961 0%, #F76B1C 100%);
}
.profile-action-color3 {
	background-image: linear-gradient(134deg, #3023AE 0%, #C86DD7 100%);
}
.profile-action-color4 {
	background-image: linear-gradient(90deg, #26AE3C 0%, #ABFF9E 100%);
}

.profile-action-color1:hover {
	background-image: linear-gradient(-45deg, #F39FA6 0%, #9F041B 100%);
}
.profile-action-color2:hover {
	background-image: linear-gradient(-45deg, #FFE997 0%, #F76B1C 100%);
}
.profile-action-color3:hover {
	background-image: linear-gradient(134deg, #3023AE 0%, #D5ABDC 100%);
}
.profile-action-color4:hover {
	background-image: linear-gradient(90deg, #26AE3C 0%, rgba(171,255,158,0.46) 100%);
}



.fa-briefcase, .fa-user-circle, .fa-graduation-cap, .fa-calendar-check {
	color: #fff;
	font-size: 1.5em;
	margin-right: .5em;
}



.profile-actions h4 {
	font-family: Open Sans;
	font-weight: 600;
	font-size: 1.2em;
	color: #fff;
}

/*  */




// social links
.dashboard-row-3-card-1-box {
	background-color: #FFFFFF;
	border-radius: 18px;
	max-height: 150px;
	width: 100%;
	padding: 30px; 
	padding-top: 25px;
	margin-right: 25px;
}

.dashboard-row-3-card-1-wrap {
	display: flex;
	// justify-content: flex-end;
	align-items: flex-end;
	height: 100%;
}

.dashboard-row-3-card-1-inner-wrap {
	display: flex;
	flex-flow: row;
	// justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
}



.dashboard-row-3-card-1-btn-wrap {
	margin-left: 35px;
	
	&:hover {
		.dashboard-row-3-card-1-btn-text {
			color: #EC3F4F;
		}
		text-decoration: none;
	}
}


.dashboard-row-3-card-1-btn-wrap,
.dashboard-row-3-card-1-btn-wrap-2
 {
	display: flex;
	justify-content: space-between;
	flex-flow: row;
	align-items: center;
	background: #FFF0F1;
	width: 120px;
	height: 35px;
	border-radius: 20px;
	padding-left: 10px;
	padding-right: 10px;

	&:hover {
		cursor: pointer;
	.dashboard-social-icon {
		
		stroke: rgb(235, 62, 79);
		
		text-decoration: none;
		// border: 1px solid $red;
		transition: .15s
		}
	}

}


.dashboard-row-3-card-1-btn-wrap-2 {
	margin-left: 20px;
}




// client connections
.dashboard-row-3-card-2-box {
	background-color: #FFFFFF;
	border-radius: 18px;
	max-height: 150px;
	
	padding: 30px; 
	padding-top: 25px;
	margin-right: 25px;
	width: 100%;
}

.dashboard-row-3-card-2-wrap {
	display: flex;
	align-items: flex-end;
	height: 100%;
}

.dashboard-row-3-card-2-inner-wrap {
	margin-bottom: 10px;
}

.dashboard-row-3-card-2-text {
	font-family: 'Roboto';
	font-size: 40px;
	color: #A58C8D;
}



// profile views
.dashboard-row-3-card-3-box {
	background-color: #FFFFFF;
	border-radius: 18px;
	max-height: 150px;
	
	padding: 30px; 
	padding-top: 25px;
	// margin-right: 25px;
	width: 100%;
}

.dashboard-row-3-card-3-wrap {
	display: flex;
	align-items: flex-end;
	height: 100%;
}

.dashboard-row-3-card-3-inner-wrap {
	margin-bottom: 10px;
}

.dashboard-row-3-card-3-text {
	font-family: 'Roboto';
	font-size: 40px;
	color: #A58C8D;
}



// connection req table

.dashboard-table {
	margin-top: 0;
	height: 200px;
}




//  spans

.dashboard-row-2-card-2 > span,
.dashboard-row-3-card-1-box > span,
.dashboard-row-3-card-2-box > span,
.dashboard-row-3-card-3-box > span {
	color: #3E2222;
}



// profile image
.dashboard-profile-image {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}



// td
.td-1-dashboard {
	font-weight: 400;
	font-family: 'Roboto';
	color:  rgb(146, 123, 125);
	background-color: rgba(238, 218, 218, 0.425);
	border-radius: 10px;
	padding: 8px;
}

.td-2-dashboard {
	font-weight: 400;
	font-family: 'Roboto';
	color:  rgb(146, 123, 125);
	background-color: rgba(238, 218, 218, 0.425);
	border-radius: 10px;
	padding: 8px;
}


.td-email:hover {
	cursor: pointer;
}



