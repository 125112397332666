.edit-avail-modal > #modal-container {
    height: auto;
}

.edit-avail-modal-form {
    display: flex;
    flex-flow: row wrap;
   justify-content: space-between;
   /* width: 200px; */
   margin-top: -15px;
   padding-left: 70px;
   padding-right: 70px;
}

.avail-modal-select-container {
    display: flex;
    flex-flow: row wrap;
    
}



  .avail-modal-select-list-input-1, .avail-modal-select-list-input-2 {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 8px;
    border: 1px solid #D3B9B9;
    padding: 5px 20px;
    width: 90px;
    height: 35px;
}

.avail-modal-select-list-input-1:focus , .avail-modal-select-list-input-2:focus {
    border: 1px solid #3689FF;
}

.avail-modal-select-list-input-1 {
    margin-right: 10px;
}