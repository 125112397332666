// @import 'reset';
@import 'variables';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  // background-color: $white;
  background-color: #F6F0F0;
  overflow-y: scroll;
}

.Main {
  display: flex;
  flex-flow: row;
}

.copyright {
  text-align: center;
  font-size: .9em;
  color: #6c757d;
  margin-top: 60px;
}