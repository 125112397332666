.profile-card-p {

	box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
  height: 220px;
  width: 65%;
  margin-top: 2em;

  background: #FFFFFF;
  border: 2px solid #A38C8C;
  border-radius: 8px;

  &:hover {
    border: 2px solid #F64949;
    transition: .15s;
  }


  @media screen and (max-width: 1102px) {
    width: 90%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  
  @media screen and (max-width: 767px) {
    height: 250px;
  }

}

.profile-card-image  {
  text-align: center;
}

.profile-card-image {
  /* width: 150px; */
  margin-left: 2em;
  margin-top: .5em;
  margin-bottom: 2.3em;
}

.profile-card-img {
  min-width: 100px;
  min-height: 100px;
  max-width:  100px;
  max-height: 100px;

  @media screen and (max-width: 767px) {
    width: 100px;
    height: 100px;
  }
}

.profile-card-image p {
  font-family: Open Sans;
  font-size: 16px;
  color: #9B9B9B;
  margin-top: 20px;
}

/**/

.profile-card-title {
  display: flex;
  flex-flow: column;


  @media screen and (max-width: 767px) {
    
  }


}


.profile-card-title h3 {
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 28px;
  color: #3E2222;
  margin-right: 0px;
}
 
// .profile-card-title p { 
//   font-family: 'DM Sans';
//   font-size: 20px;
//   color: #715F5F;
// }

// .profile-card-title span {
//   text-overflow: ellipsis;
//   font-size: 16px;
//   font-weight: 300;
//   color: #715F5F;
// }

/**/
.profile-card-info-wrapper {
  display: flex;
  flex-flow: column;


  @media screen and (max-width: 767px) {
    display: flex;
    flex-flow: row;
  
  }
}




.profile-card-info h4 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  color: #3E2222;
}




// .profile-card-info p { 
//   font-family: 'Roboto';
//   font-size: 16px;
//   color: #A48C8D;
//   margin-left: 2.5em;
// }

// .profile-card-info-2 p {
//   margin-left: 2.5em;
// } 

// .profile-card-title hr {
//   width: 500px;
//   @media screen and (max-width: 992px) {
//     width: 200px;
//   }
//   @media screen and (max-device-width:767px){
//     display: none;
    
//   }
// }

// .profile-card-button button {
//   padding: 13px 27px 13px 27px;
//   margin-left: 0em;
//   margin-right: 0em;
//   margin-top: 0em;
  
// }
/**/

.profile-card-button button {
  float: right;
  background-image: linear-gradient(-82deg, #F32C54 3%, #F64949 96%);
  border-radius: 5px;
  /* box-shadow: 0 1px 6px 0 rgba(0,0,0,0.50); */
  font-family: 'DM Sans';
  font-weight: 700;
  border: none;
 
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  padding: 13px 27px 13px 27px;
  
  margin-bottom: 1em;

  /* margin-left: 20em; */
  @media screen and (max-width: 992px) {

  }
  @media screen and (max-device-width:767px){
    margin-bottom: 0em;
    
  }
}


.profile-card-button button:hover {
  opacity: .8;
  transition: .15s;
}


.card-info {
  display: flex;
  flex-flow: row;
  justify-content:space-between;
  width: 100%;
  margin-left: 50px;
  
  @media screen and (max-device-width:767px){
   
  }
}

.card-p {
	padding: 20px 35px 30px 10px;
  display: flex;
  justify-content: flex-start;
  /*flex-flow: row wrap;*/
}


/**/




/* 
 */

 .cc-trusted {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 11.5px;
  color: #F53551;
 }

 .fa-award {
  color: #CC2727;
 }

 .default-pic-background {
   background: #D8D8D8;
   margin-left: 3em;
   margin-top: 1em;
   height: 140px;
   width: 140px;
 }


/* On screens that are 992px or less, set the background color to blue */
// @media screen and (max-width: 992px) {

//   .profile-card-p{
//     margin-right: 50px;
//     margin-left: 50px;
//   }

//   .profile-card-button button {
//     padding: 13px 27px 13px 27px;
//     margin-left: 12em;
//     margin-right: 0em;
//     margin-top: -3em;
//   }

// }


// MOBILE STUFF
.profile-card-info-wrapper-mobile {
  display: none;
}

.profile-card-title-mobile {
  display: none;
}

.cc-trusted-mobile {
  display: none;
}
 
.cc-trusted-img-mobile {
  display: none;
}


.profile-card-p {
 
  @media screen and (max-device-width:767px){
    height: auto;
    padding-bottom: 12%;
  }

  // iphone 5
  @media screen and (max-device-width:360px){
    // min-height: 230px;
    height: auto;
    padding-bottom: 40%;

  }
}

.employee-card {
  @media screen and (max-device-width:767px){
    height: auto;
    padding-bottom: 12%;
  }

  // iphone 5
  @media screen and (max-device-width:360px){
    min-height: 230px;
  }
}







@media screen and (max-device-width:767px){



// MOBILE STUFF
.cc-trusted-img-mobile {
  display: block;
}

.profile-card-info-wrapper-mobile {
  display: block;
}

.profile-card-title-mobile {
  display: block;
  font-size: 20px;
  margin-top: 1em;
  font-family: 'DM Sans';
  color: #3F2223;
}

.profile-card-title {
  display: none;
}

.profile-card-info-wrapper {
  display: none;
}

.profile-card-image {
  display: flex;
  flex-flow: column;
}

.cc-trusted-img-mobile-wrap { 
  display: flex;
  flex-flow: row;
}

.cc-trusted-mobile {
  display: block;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 10px;
  color: #F53551;
  text-align: left;
  margin-left: 10px;
}

.cc-trusted-img-mobile {
   height: 25px;
   width: 25px;
 }


.viewlaw-profile-btn-wrap {
  display: flex;
  justify-content: space-between;
  margin-right: 0em;
}


  // 
  .profile-fields {
    display: none;
  }

.profile-card-button button {
  padding: 13px 27px 13px 27px;
  font-size: 12px;
}


.profile-card-button {
  margin-right: 1em;
}



.card-p {
  display: flex;
  flex-flow: row;

  margin-top: 1em;
  padding: 0px 0px 0px 15px;
  height: auto;
}

.cc-trusted {
  display: none;
}



.default-pic-background {
  width: 100px;
  height: 100px;
  margin-left: 5px;
} 

.profile-card-image {
  width: 100px;
  height: 100px;
  margin-left: 5px;
  margin-bottom:0em;
}

.profile-card-image p {
  margin-top: 35px;
  margin-left: 15px;
}

.viewlaw-profile-btn-wrap { 
  margin-top: .7em;
}



/**/





.profile-card-title h3 {
  
  font-size: 18px;
  color: #4A4A4A;
  margin-top: 20px;
}
 
.profile-card-title p { 
  font-family: Open Sans;
  font-size: 14px;
  color: rgb(192, 182, 182);
}

/**/
.profile-card-info {
  margin-right: 2em;
  margin-left: 0em;
  // display: none;
}

.profile-card-info h4 {
  font-size: 14px;
  color: #4A4A4A;
}



.profile-card-info p { 
  font-family: 'DM Sans';
  font-size: 13px;
  color: #9B9B9B;
  margin-left: 0em;
 
}

// .profile-card-button-mobile {
//   display: block;
//   margin-top: -.5em;
//   margin-left: 6.5em;

// }


}



.viewlaw-profile-btn-wrap {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}



@media screen and (max-device-width: 360px) {
  .profile-card-info-2 {
    display: none;
  }

  .profile-card-button button {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    margin-right: .2em;
  }

}




.profile-card-info-addr-label, .profile-card-info-language-label {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 18px;
  color: #3F2223;
}

.profile-card-info-address-value,
.profile-card-info-language-value {
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 16px;
  color: #A48C8D;
}


// EMPLOYEE CARD

.employee-card {
  width: 100%;

  .employee-card-img {
    min-width: 100px;
  min-height: 100px;
  max-width:  100px;
  max-height: 100px;
    margin-top: 1em;
  }
  .emp-card-cc-trusted-wrap {
    display: flex;
    flex-flow: row;

 


    .emp-card-cc-trusted-img {
      color: #F53551;
    }

    .emp-card-cc-trusted-txt {
      display: block;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 10px;
      color: #F53551;
      text-align: left;
      margin-left: 10px;
    }
    
   
  }
}

   
.emp-card-address {
  @media screen and (max-width: 767px) {
    display: none;
  }
 }